import { ITDAmeritradeStreamQuote } from './../interfaces/ITDAmeritradeStreamQuote'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import IOption from '../interfaces/IOption'
import { ICustomizableTableConfigItem } from '../config/defaultTableConfig'
import { zeroDteTableConfig } from '../config/zeroDteTableConfig'
import { IOptionOrder } from '../interfaces/IOptionOrder'

export interface ZeroDteState {
  zeroDteDataLastUpdated: number
  quotes: Array<ITDAmeritradeStreamQuote>
  currentPrice: number
  currentQuoteTime: number
  prevOptions: Array<IOption>
  allOptions: Array<IOption>
  optionOrders: Array<IOptionOrder>
  largeOrders: Array<IOptionOrder>
  currentOptionsList: Array<IOption>
  callExposures: Array<{ x: number; y: number }>
  putExposures: Array<{ x: number; y: number }>
  totalCallVolumeData: Array<{ x: number; y: number }>
  totalPutVolumeData: Array<{ x: number; y: number }>
  putCallSpreadExposures: Array<{ x: number; y: number }>
  activeIndexSymbol: string
  activeDaysToExpiration: number
  activeStrategy: string
  isChartOpen: boolean
  activeSortProperty: keyof IOption
  isDescending: boolean
  zeroDteTableConfig: Array<ICustomizableTableConfigItem>
  activeSpreadWidth: number
  strikeGammaMap: Array<{ strike: number; gammaExposure: number }>
  whaleOrders: Array<IOptionOrder>
  longCallPremiums: Array<{ x: number; y: number }>
  longPutPremiums: Array<{ x: number; y: number }>
}

export const zeroDteInitialState: ZeroDteState = {
  zeroDteDataLastUpdated: new Date().getTime(),
  quotes: [],
  currentPrice: 0,
  currentQuoteTime: 0,
  prevOptions: [],
  allOptions: [],
  optionOrders: [],
  largeOrders: [],
  currentOptionsList: [],
  callExposures: [],
  putExposures: [],
  totalCallVolumeData: [],
  totalPutVolumeData: [],
  putCallSpreadExposures: [],
  activeIndexSymbol: 'SPY',
  activeStrategy: 'S',
  activeDaysToExpiration: 0,
  isChartOpen: false,
  activeSortProperty: 'strikePrice',
  isDescending: true,
  zeroDteTableConfig,
  activeSpreadWidth: 1,
  strikeGammaMap: [],
  whaleOrders: [],
  longCallPremiums: [],
  longPutPremiums: []
}

const getCurrentOptionsList = (state: ZeroDteState) => {
  // visible options are the ones with the currently selected expiration date and strategy, and sort by sort value
  const options = state.allOptions
    .sort((a, b) => {
      // ensure we are only on number or string properties
      const propA = a[state.activeSortProperty]
      const propB = b[state.activeSortProperty]
      if (typeof propA === 'number' && typeof propB === 'number') {
        if (state.isDescending) {
          return propB - propA
        } else {
          return propA - propA
        }
      } else {
        return 0
      }
    })
    .map((option) => {
      const breakEvenPrice = option.strikePrice - option.mark
      const breakEvenPercent = (option.currentPrice - breakEvenPrice) / option.currentPrice
      const maxProfit = option.mark * 100
      const maxLoss = option.currentPrice * 100
      const rewardToRiskRatio = (option.mark * 100) / (option.currentPrice * 100)
      const probabilityOfProfit = (1 - Math.abs(option.delta)) * 100
      return {
        ...option,
        breakEvenPrice,
        breakEvenPercent,
        maxProfit,
        maxLoss,
        rewardToRiskRatio,
        probabilityOfProfit
      }
    })

  // special case for when we are switching to spreads, we need to recalculate all current options
  // and return those instead
  const spreadOptions: Array<IOption> = []
  if (state.activeStrategy === 'CCS') {
    // sort by strike price ascending
    options.sort((a, b) => {
      return a.strikePrice - b.strikePrice
    })

    // loop over all options twice and use the spread width to set the 'option' properties
    for (let i = 0; i < options.length - state.activeSpreadWidth; i++) {
      const shortLeg = options[i]
      const longLeg = options[i + state.activeSpreadWidth]
      const netCredit = shortLeg.mark - longLeg.mark
      const maxProfit = netCredit * 100
      const maxLoss = (longLeg.strikePrice - shortLeg.strikePrice - netCredit) * 100
      const breakEvenPrice = shortLeg.strikePrice + netCredit
      const breakEvenPercent = ((breakEvenPrice - shortLeg.currentPrice) / shortLeg.currentPrice) * 100
      const rewardToRiskRatio = (maxProfit / maxLoss) * 100
      const delta = longLeg.delta - shortLeg.delta

      spreadOptions.push({
        ...shortLeg,
        symbol: shortLeg.symbol + ' ' + longLeg.symbol,
        maxProfit,
        maxLoss,
        breakEvenPrice,
        breakEvenPercent,
        rewardToRiskRatio,
        probabilityOfProfit: 100 - Math.abs(delta * 100),
        bid: longLeg.bid - shortLeg.bid,
        ask: longLeg.ask - shortLeg.ask,
        mark: longLeg.mark - shortLeg.mark,
        delta,
        gamma: longLeg.gamma - shortLeg.gamma,
        theta: longLeg.theta - shortLeg.theta,
        vega: longLeg.vega - shortLeg.vega,
        rho: longLeg.rho - shortLeg.rho,
        // good stuff for live module
        // shortLongInfos: {
        //   strikePrice: [shortLeg.strikePrice, longLeg.strikePrice],
        //   bidSize: [shortLeg.bidSize, longLeg.bidSize],
        //   askSize: [shortLeg.askSize, longLeg.askSize],
        //   openInterest: [shortLeg.openInterest, longLeg.openInterest],
        //   totalVolume: [shortLeg.totalVolume, longLeg.totalVolume],
        //   volatility: [shortLeg.volatility, longLeg.volatility]
        // },
        contractType: 'CCS'
      })
    }
    // again sort by strike price descending
    return spreadOptions.sort((a, b) => {
      return b.strikePrice - a.strikePrice
    })
  }
  if (state.activeStrategy === 'PCS') {
    // sort by strike price ascending
    options.sort((a, b) => {
      return a.strikePrice - b.strikePrice
    })
    // loop over all options twice and use the spread width to set the 'option' properties
    for (let i = 0; i < options.length - state.activeSpreadWidth; i++) {
      const longLeg = options[i]
      const shortLeg = options[i + state.activeSpreadWidth]
      const netCredit = shortLeg.mark - longLeg.mark
      const maxProfit = netCredit * 100
      const maxLoss = (shortLeg.strikePrice - longLeg.strikePrice - netCredit) * 100
      const breakEvenPrice = shortLeg.strikePrice - netCredit
      const breakEvenPercent = ((breakEvenPrice - shortLeg.currentPrice) / shortLeg.currentPrice) * 100
      const rewardToRiskRatio = (maxProfit / maxLoss) * 100
      const delta = longLeg.delta - shortLeg.delta
      spreadOptions.push({
        ...shortLeg,
        symbol: shortLeg.symbol + ' ' + longLeg.symbol,
        maxProfit,
        maxLoss,
        breakEvenPrice,
        breakEvenPercent,
        rewardToRiskRatio,
        probabilityOfProfit: 100 - Math.abs(delta * 100),
        bid: shortLeg.bid - longLeg.bid,
        ask: shortLeg.ask - longLeg.ask,
        mark: shortLeg.mark - longLeg.mark,
        delta: delta,
        gamma: longLeg.gamma - shortLeg.gamma,
        theta: longLeg.theta - shortLeg.theta,
        vega: longLeg.vega - shortLeg.vega,
        rho: longLeg.rho - shortLeg.rho,
        // shortLongInfos: {
        //   strikePrice: [shortLeg.strikePrice, longLeg.strikePrice],
        //   bidSize: [shortLeg.bidSize, longLeg.bidSize],
        //   askSize: [shortLeg.askSize, longLeg.askSize],
        //   openInterest: [shortLeg.openInterest, longLeg.openInterest],
        //   totalVolume: [shortLeg.totalVolume, longLeg.totalVolume],
        //   volatility: [shortLeg.volatility, longLeg.volatility]
        // },
        contractType: 'PCS'
      })
    }
    // again sort by strike price descending
    return spreadOptions.sort((a, b) => {
      return b.strikePrice - a.strikePrice
    })
  }

  // ensure unique by symbol
  const uniqueOptions = options.filter((option, index, self) => {
    return index === self.findIndex((t) => t.symbol === option.symbol)
  })

  return uniqueOptions
}

export const zeroDteSlice = createSlice({
  name: 'zerodte',
  initialState: zeroDteInitialState,
  reducers: {
    setZeroDteDataLastUpdated: (state, action: PayloadAction<number>) => {
      state.zeroDteDataLastUpdated = action.payload
    },
    appendToQuotes: (state, action: PayloadAction<Array<ITDAmeritradeStreamQuote>>) => {
      if (action.payload.length === 1 && action.payload[0] !== undefined && action.payload[0].mark !== undefined) {
        state.currentPrice = action.payload[0].mark
        state.currentQuoteTime = action.payload[0].quoteTimeInLong
        state.quotes = [...state.quotes, ...action.payload]
      }
    },
    setOptions: (state, action: PayloadAction<Array<IOption>>) => {
      const newOptions = action.payload

      // filter those which have an undefined bid
      const filteredOptions = newOptions.filter((option) => option.bid !== undefined)

      // special case - we are starting up and we have no options yet - just set them
      if (state.allOptions.length === 0) {
        state.allOptions = filteredOptions
        // also update the visible options
        const currentOptionsList = getCurrentOptionsList(state)
        state.currentOptionsList = currentOptionsList

        return
      }

      // important here - set prev options to the current options
      state.prevOptions = state.allOptions

      // ensure incoming options are unique by symbol before setting them to allOptions:
      // append it to allOptions if it can't be found, otherwise replace existing option
      state.allOptions = state.allOptions.map((existingOption) => {
        const newMatchingOption = filteredOptions.find((newOption) => newOption.symbol === existingOption.symbol)
        // if we have an existing option, we need to replace all properties from the incoming option that are not undefined
        if (newMatchingOption) {
          // loop over all properties of the incoming option
          for (const [key, value] of Object.entries(newMatchingOption)) {
            if (key === 'strikePrice' || key === 'symbol') {
              continue
            }
            // if the value is not undefined, we replace the existing option's property with the incoming option's property
            if (value !== undefined) {
              ;(existingOption as any)[key] = value
            }
          }
          return existingOption
        }
        // if we don't have an existing option, we just return the incoming option
        return existingOption
      })

      // also update the visible options
      const currentOptionsList = getCurrentOptionsList(state)
      state.currentOptionsList = currentOptionsList
    },
    setOptionOrders: (state, action: PayloadAction<Array<IOptionOrder>>) => {
      state.optionOrders = action.payload
    },
    clearOptionOrders: (state) => {
      state.optionOrders = []
    },
    addToLargeOrders: (state, action: PayloadAction<IOptionOrder>) => {
      // add it to list, sort by date descending, and only keep the last 50, also ensure unique by quoteTimeInLong
      const largeOrderList = [...state.largeOrders, action.payload]
        .sort((a, b) => {
          return b.quoteTimeInLong - a.quoteTimeInLong
        })
        .filter((order, index, self) => {
          return index === self.findIndex((t) => t.quoteTimeInLong === order.quoteTimeInLong)
        })
        .slice(0, 25)

      state.largeOrders = largeOrderList
    },
    setActiveIndexSymbol: (state, action: PayloadAction<string>) => {
      // clear all options related data
      state.allOptions = []
      state.prevOptions = []
      state.currentOptionsList = []
      state.quotes = []
      state.currentPrice = 0
      state.currentQuoteTime = 0
      state.callExposures = []
      state.putExposures = []
      state.optionOrders = []
      state.largeOrders = []
      state.whaleOrders = []
      state.putCallSpreadExposures = []
      state.totalPutVolumeData = []
      state.totalCallVolumeData = []
      state.longCallPremiums = []
      state.longPutPremiums = []
      state.strikeGammaMap = []
      state.activeIndexSymbol = action.payload

      // also update the visible options
      const currentOptionsList = getCurrentOptionsList(state)
      state.currentOptionsList = currentOptionsList
    },
    setActiveStrategy: (state, action: PayloadAction<string>) => {
      state.activeStrategy = action.payload

      // always ensure strike label of config is set according to whatever strategy is being set
      state.zeroDteTableConfig = state.zeroDteTableConfig.map((configItem) => {
        if (configItem.property === 'strikePrice') {
          configItem.label = action.payload === 'CCS' || action.payload === 'PCS' ? 'Short / Long' : 'Strike'
        }
        return configItem
      })

      // also update the visible options
      const currentOptionsList = getCurrentOptionsList(state)

      state.currentOptionsList = currentOptionsList
    },
    setActiveSpreadWidth: (state, action: PayloadAction<number>) => {
      state.activeSpreadWidth = action.payload
    },
    setActiveDaysToExpiration: (state, action: PayloadAction<number>) => {
      state.activeDaysToExpiration = action.payload

      // also update the visible options
      const currentOptionsList = getCurrentOptionsList(state)
      state.currentOptionsList = currentOptionsList
    },
    setIsChartOpen: (state, action: PayloadAction<boolean>) => {
      state.isChartOpen = action.payload
    },
    setSortInfo: (state, action: PayloadAction<{ activeSortProperty: keyof IOption }>) => {
      const { activeSortProperty } = action.payload
      if (state.activeSortProperty === activeSortProperty) {
        state.isDescending = !state.isDescending
      } else {
        state.activeSortProperty = activeSortProperty
        state.isDescending = true
      }

      // also update table config for UI changes
      state.zeroDteTableConfig = state.zeroDteTableConfig.map((configItem) => {
        if (configItem.property === activeSortProperty) {
          configItem.isSortActive = true
          configItem.isSortDescending = state.isDescending
        } else {
          configItem.isSortActive = false
          configItem.isSortDescending = false
        }
        return configItem
      })

      // also update the visible options
      const currentOptionsList = getCurrentOptionsList(state)
      state.currentOptionsList = currentOptionsList
    },
    appendToCallExposures: (state, action: PayloadAction<{ x: number; y: number }>) => {
      state.callExposures = [...state.callExposures, action.payload]
    },
    appendToPutExposures: (state, action: PayloadAction<{ x: number; y: number }>) => {
      state.putExposures = [...state.putExposures, action.payload]
    },
    appendToPutCallSpreadExposures: (state, action: PayloadAction<{ x: number; y: number }>) => {
      state.putCallSpreadExposures = [...state.putCallSpreadExposures, action.payload]
    },
    appendToTotalCallVolumeData: (state, action: PayloadAction<{ x: number; y: number }>) => {
      state.totalCallVolumeData = [...state.totalCallVolumeData, action.payload]
    },
    appendToTotalPutVolumeData: (state, action: PayloadAction<{ x: number; y: number }>) => {
      state.totalPutVolumeData = [...state.totalPutVolumeData, action.payload]
    },
    modifyStrikeGammas: (state, action: PayloadAction<Array<{ strike: number; gammaExposure: number }>>) => {
      action.payload.forEach((item) => {
        const existingStrikeGamma = state.strikeGammaMap.find((x) => x.strike === item.strike)
        // if existing, add to existing gamma (works wether gamma is positive or negative!)
        if (existingStrikeGamma) {
          existingStrikeGamma.gammaExposure += item.gammaExposure
        } else {
          // if not existing, add to map
          state.strikeGammaMap.push(item)
        }
      })
    },
    appendToWhaleOrder: (state, action: PayloadAction<IOptionOrder>) => {
      const whaleOrderList = [action.payload, ...state.whaleOrders]
        .sort((a, b) => {
          return b.quoteTimeInLong - a.quoteTimeInLong
        })
        .filter((order, index, self) => {
          return index === self.findIndex((t) => t.quoteTimeInLong === order.quoteTimeInLong)
        })
        .slice(0, 25)

      state.whaleOrders = whaleOrderList
    },
    addLongCallPremium: (state, action: PayloadAction<number>) => {
      // if we have a previous entry, add the incoming premium to it and set it as the next entry
      if (state.longCallPremiums.length > 0) {
        const prev = state.longCallPremiums[state.longCallPremiums.length - 1]
        const next = { x: new Date().getTime(), y: prev.y + action.payload }
        state.longCallPremiums = [...state.longCallPremiums, next]
      }
    },
    addLongPutPremium: (state, action: PayloadAction<number>) => {
      // if we have a previous entry, add the incoming premium to it and set it as the next entry
      if (state.longPutPremiums.length > 0) {
        const prev = state.longPutPremiums[state.longPutPremiums.length - 1]
        const next = { x: new Date().getTime(), y: prev.y + action.payload }
        state.longPutPremiums = [...state.longPutPremiums, next]
      }
    }
  }
})

export const {
  setZeroDteDataLastUpdated,
  appendToQuotes,
  setOptions,
  setOptionOrders,
  clearOptionOrders,
  addToLargeOrders,
  setActiveIndexSymbol,
  setActiveStrategy,
  setActiveSpreadWidth,
  setActiveDaysToExpiration,
  setIsChartOpen,
  setSortInfo,
  appendToCallExposures,
  appendToPutExposures,
  appendToPutCallSpreadExposures,
  appendToTotalCallVolumeData,
  appendToTotalPutVolumeData,
  modifyStrikeGammas,
  appendToWhaleOrder,
  addLongCallPremium,
  addLongPutPremium
} = zeroDteSlice.actions

export default zeroDteSlice.reducer
