import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Broker from '../enums/Broker'

export interface BrokerState {
  broker: Broker
}

export const brokerInitialState: BrokerState = {
  broker: Broker.NONE
}

export const brokerSlice = createSlice({
  name: 'broker',
  initialState: brokerInitialState,
  reducers: {
    setBroker: (state, action: PayloadAction<Broker>) => {
      state.broker = action.payload
    }
  }
})

export const { setBroker } = brokerSlice.actions

export default brokerSlice.reducer
