import { IIndustry } from '../interfaces/IIndustry'

export const countriesConfig: Array<IIndustry> = [
  { name: 'Argentina' },
  { name: 'Australia' },
  { name: 'Belgium' },
  { name: 'Bermuda' },
  { name: 'Brazil' },
  { name: 'Canada' },
  { name: 'Cayman Islands' },
  { name: 'Chile' },
  { name: 'China' },
  { name: 'Colombia' },
  { name: 'Cyprus' },
  { name: 'Denmark' },
  { name: 'Finland' },
  { name: 'France' },
  { name: 'Germany' },
  { name: 'Greece' },
  { name: 'Hong Kong' },
  { name: 'India' },
  { name: 'Ireland' },
  { name: 'Israel' },
  { name: 'Italy' },
  { name: 'Japan' },
  { name: 'Kazakhstan' },
  { name: 'Luxembourg' },
  { name: 'Mexico' },
  { name: 'Monaco' },
  { name: 'Netherlands' },
  { name: 'Norway' },
  { name: 'Panama' },
  { name: 'Singapore' },
  { name: 'South Africa' },
  { name: 'South Korea' },
  { name: 'Spain' },
  { name: 'Sweden' },
  { name: 'Switzerland' },
  { name: 'Taiwan' },
  { name: 'United Kingdom' },
  { name: 'USA' }
]
