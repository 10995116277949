import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import IOption from '../interfaces/IOption'
import { IQuote } from '../interfaces/IQuote'

// see: https://documentation.tradier.com/brokerage-api/oauth/access-token
export interface ITradierAccessToken {
  access_token: string
  expires_in: number
  issued_at: string
  scope: string
  token_type: string
}

export interface TradierState {
  tradierAccessToken: ITradierAccessToken | null
  tradierOptions: IOption[]
  tradierQuotes: IQuote[]
}

export const tradierInitialState: TradierState = {
  tradierAccessToken: null,
  tradierOptions: [],
  tradierQuotes: []
}

export const tradierSlice = createSlice({
  name: 'tradier',
  initialState: tradierInitialState,
  reducers: {
    setTradierAccessToken: (state, action: PayloadAction<ITradierAccessToken | null>) => {
      state.tradierAccessToken = action.payload
    },
    setTradierOptions: (state, action: PayloadAction<IOption[]>) => {
      state.tradierOptions = action.payload
    },
    setTradierQuotes: (state, action: PayloadAction<IQuote[]>) => {
      state.tradierQuotes = action.payload
    }
  }
})

export const { setTradierAccessToken, setTradierOptions, setTradierQuotes } = tradierSlice.actions

export default tradierSlice.reducer
