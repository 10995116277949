import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ITDAmeritradeUserPrincipals } from '../interfaces/ITDAmeritradeUserPrincipals'
import IOption from '../interfaces/IOption'
import { IQuote } from '../interfaces/IQuote'

export interface TDAmeritradeState {
  userPrincipals: ITDAmeritradeUserPrincipals | null
  webSocketConnected: boolean
  requestId: number
  tdAmeritradeOptions: IOption[]
  tdAmeritradeQuotes: IQuote[]
}

export const tdameritradeInitialState: TDAmeritradeState = {
  userPrincipals: null,
  webSocketConnected: false,
  requestId: 0,
  tdAmeritradeOptions: [],
  tdAmeritradeQuotes: []
}

export const tdameritradeSlice = createSlice({
  name: 'tdameritrade',
  initialState: tdameritradeInitialState,
  reducers: {
    setUserPrincipals: (state, action: PayloadAction<ITDAmeritradeUserPrincipals | null>) => {
      state.userPrincipals = action.payload
    },
    setTDAmeritradeWebSocketConnected: (state, action: PayloadAction<boolean>) => {
      state.webSocketConnected = action.payload
    },
    incrementRequestId: (state) => {
      // increment by two because we always do a quote and an option subscription together (unsubscribe together as well)
      state.requestId += 2
    },
    setTDAmeritradeOptions: (state, action: PayloadAction<IOption[]>) => {
      state.tdAmeritradeOptions = action.payload
    },
    setTDAmeritradeQuotes: (state, action: PayloadAction<IQuote[]>) => {
      state.tdAmeritradeQuotes = action.payload
    }
  }
})

export const { setUserPrincipals, setTDAmeritradeWebSocketConnected, incrementRequestId, setTDAmeritradeOptions, setTDAmeritradeQuotes } =
  tdameritradeSlice.actions

export default tdameritradeSlice.reducer
