enum UserRole {
  PUBLIC = '',
  FREE = 'free',
  PREMIUM = 'premium', // legacy, to be converted to PREMIUM_MONTHLY
  LIFETIME = 'lifetime', // legacy, to be converted to PREMIUM_LIFETIME
  STARTER_LIFETIME = 'starter-lifetime',
  STARTER_MONTHLY = 'starter-monthly',
  PREMIUM_LIFETIME = 'premium-lifetime',
  PREMIUM_MONTHLY = 'premium-monthly'
}
export default UserRole

export const isPremium = (userState: UserRole) => {
  return userState === UserRole.PREMIUM || userState === UserRole.PREMIUM_LIFETIME || userState === UserRole.PREMIUM_MONTHLY
}

export const isStarter = (userState: UserRole) => {
  return userState === UserRole.STARTER_LIFETIME || userState === UserRole.STARTER_MONTHLY
}
