import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import IOption from '../interfaces/IOption'
import { IQuote } from '../interfaces/IQuote'

export interface TradeStationState {
  tradeStationAccessToken: string | null
  tradeStationOptions: IOption[]
  tradeStationQuotes: IQuote[]
}

export const tradeStationInitialState: TradeStationState = {
  tradeStationAccessToken: null,
  tradeStationOptions: [],
  tradeStationQuotes: []
}

export const tradeStationSlice = createSlice({
  name: 'tradeStation',
  initialState: tradeStationInitialState,
  reducers: {
    setTradeStationAccessToken: (state, action: PayloadAction<string | null>) => {
      state.tradeStationAccessToken = action.payload
    },
    setTradeStationOptions: (state, action: PayloadAction<IOption[]>) => {
      state.tradeStationOptions = action.payload
    },
    setTradeStationQuotes: (state, action: PayloadAction<IQuote[]>) => {
      state.tradeStationQuotes = action.payload
    }
  }
})

export const { setTradeStationAccessToken, setTradeStationOptions, setTradeStationQuotes } = tradeStationSlice.actions

export default tradeStationSlice.reducer
