import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IStock } from '../interfaces/IStock'

export interface StocksState {
  stocks: Array<IStock>
}

export const stocksInitialState: StocksState = {
  stocks: []
}

export const stocksSlice = createSlice({
  name: 'stocks',
  initialState: stocksInitialState,
  reducers: {
    setStocks: (state, action: PayloadAction<Array<IStock>>) => {
      state.stocks = action.payload
    }
  }
})

export const { setStocks } = stocksSlice.actions

export default stocksSlice.reducer
