import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Constants } from '../constants/Constants'
import IOption from '../interfaces/IOption'
import IDatasetInfoViewModel from '../interfaces/IDatasetInfoModel'
import DatasetType from '../enums/DatasetType'
import Strategy from '../enums/Strategy'
import { IQuote } from '../interfaces/IQuote'

export interface OptionsDataState {
  options: Array<IOption>
  pageCount: number
  freePageCount: number
  sAndPPageCount: number
  datasetInfo: IDatasetInfoViewModel
  pageIndex: number
  pageSize: number
  strategy: Strategy | ''
  searchQuery: string
  orderBy: string
  orderDirection: string
  filterBy: Array<string>
  filterMin: Array<number>
  filterMax: Array<number>
  symbol: Array<string>
  industry: Array<string>
  sector: Array<string>
  country: Array<string>
  exchange: Array<string>
  excludeSymbol: Array<string>
  excludeIndustry: Array<string>
  excludeSector: Array<string>
  excludeCountry: Array<string>
  excludeExchange: Array<string>
  minMaxData: Array<{ filterBy: keyof IOption; filterMin: number; filterMax: number }>
  freeDatasetCount: number
  sAndPDatasetCount: number
  filteredCount: number
  areOptionsLoading: boolean
}

export const optionsDataInitialState: OptionsDataState = {
  options: [],
  pageCount: 0,
  freePageCount: 0,
  sAndPPageCount: 0,
  datasetInfo: {
    count: 0,
    singleContractCount: 0,
    id: 0,
    datasetType: DatasetType.BEFORE_MARKET_OPEN,
    datasetCompleteDateTime: new Date().toISOString()
  },
  pageIndex: 1,
  pageSize: Constants.OPTIONS_PER_PAGE_API,
  strategy: '',
  searchQuery: '',
  orderBy: '',
  orderDirection: '',
  filterBy: [],
  filterMin: [],
  filterMax: [],
  symbol: [],
  industry: [],
  sector: [],
  country: [],
  exchange: [],
  excludeSymbol: [],
  excludeIndustry: [],
  excludeSector: [],
  excludeCountry: [],
  excludeExchange: [],
  minMaxData: [],
  freeDatasetCount: 0,
  sAndPDatasetCount: 0,
  filteredCount: 0,
  areOptionsLoading: true
}

export const optionsDataSlice = createSlice({
  name: 'optionData',
  initialState: optionsDataInitialState,
  reducers: {
    setOptions: (state, action: PayloadAction<Array<IOption>>) => {
      state.options = action.payload
    },
    updateOption: (state, action: PayloadAction<IOption>) => {
      const index = state.options.findIndex((option) => option.optionId === action.payload.optionId)
      if (index !== -1) {
        state.options[index] = action.payload
      }
    },
    setMinMaxData: (state, action: PayloadAction<Array<{ filterBy: keyof IOption; filterMin: number; filterMax: number }>>) => {
      state.minMaxData = action.payload
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload
    },
    setPageIndex: (state, action: PayloadAction<number>) => {
      state.pageIndex = action.payload
    },
    setPageCount: (state, action: PayloadAction<number>) => {
      state.pageCount = action.payload
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload
    },
    setOrderDirection: (state, action: PayloadAction<string>) => {
      state.orderDirection = action.payload
    },
    setFilteredCount: (state, action: PayloadAction<number>) => {
      state.filteredCount = action.payload
    },
    setStrategy: (state, action: PayloadAction<Strategy | ''>) => {
      state.strategy = action.payload
    },
    setFreePageCount: (state, action: PayloadAction<number>) => {
      state.freePageCount = action.payload
    },
    setSAndPPageCount: (state, action: PayloadAction<number>) => {
      state.sAndPPageCount = action.payload
    },
    setDatasetInfo: (state, action: PayloadAction<IDatasetInfoViewModel>) => {
      state.datasetInfo = action.payload
    },
    setNumberOfFreeOptions: (state, action: PayloadAction<number>) => {
      state.freeDatasetCount = action.payload
    },
    setNumberOfSAndPOptions: (state, action: PayloadAction<number>) => {
      state.sAndPDatasetCount = action.payload
    },
    setOptionQueryParamKeyValuePair: (state, action: PayloadAction<{ key: keyof OptionsDataState; value: any }>) => {
      ;(state as any)[action.payload.key] = action.payload.value
    },
    upsertOptionDataFilter: (state, action: PayloadAction<{ filterBy: keyof IOption; filterMin: number; filterMax: number }>) => {
      const { filterBy, filterMin, filterMax } = action.payload
      const index = state.filterBy.indexOf(filterBy)
      if (index === -1) {
        state.filterBy.push(filterBy)
        state.filterMin.push(filterMin)
        state.filterMax.push(filterMax)
      } else {
        state.filterMin[index] = filterMin
        state.filterMax[index] = filterMax
      }
    },
    resetFilters: (state) => {
      state.strategy = ''
      state.filterBy = []
      state.filterMin = []
      state.filterMax = []
      state.excludeSymbol = []
      state.excludeIndustry = []
      state.excludeSector = []
      state.excludeCountry = []
    },
    setSymbols: (state, action: PayloadAction<Array<string>>) => {
      state.symbol = action.payload
    },
    addToSymbols: (state, action: PayloadAction<string>) => {
      // first check it isn't in the array already, if it is, don't add it
      if (state.symbol.indexOf(action.payload) === -1) {
        state.symbol.push(action.payload)
      }
    },
    removeFromSymbols: (state, action: PayloadAction<string>) => {
      const index = state.symbol.indexOf(action.payload)
      if (index !== -1) {
        state.symbol.splice(index, 1)
      }
    },
    addToIndustries: (state, action: PayloadAction<string>) => {
      // first check it isn't in the array already, if it is, don't add it
      if (state.industry.indexOf(action.payload) === -1) {
        state.industry.push(action.payload)
      }
    },
    removeFromIndustries: (state, action: PayloadAction<string>) => {
      const index = state.industry.indexOf(action.payload)
      if (index !== -1) {
        state.industry.splice(index, 1)
      }
    },
    addToSectors: (state, action: PayloadAction<string>) => {
      // first check it isn't in the array already, if it is, don't add it
      if (state.sector.indexOf(action.payload) === -1) {
        state.sector.push(action.payload)
      }
    },
    removeFromSectors: (state, action: PayloadAction<string>) => {
      const index = state.sector.indexOf(action.payload)
      if (index !== -1) {
        state.sector.splice(index, 1)
      }
    },
    addToExchanges: (state, action: PayloadAction<string>) => {
      // first check it isn't in the array already, if it is, don't add it
      if (state.exchange.indexOf(action.payload) === -1) {
        state.exchange.push(action.payload)
      }
    },
    removeFromExchanges: (state, action: PayloadAction<string>) => {
      const index = state.exchange.indexOf(action.payload)
      if (index !== -1) {
        state.exchange.splice(index, 1)
      }
    },
    addToCountries: (state, action: PayloadAction<string>) => {
      // first check it isn't in the array already, if it is, don't add it
      if (state.country.indexOf(action.payload) === -1) {
        state.country.push(action.payload)
      }
    },
    removeFromCountries: (state, action: PayloadAction<string>) => {
      const index = state.country.indexOf(action.payload)
      if (index !== -1) {
        state.country.splice(index, 1)
      }
    },
    addToExcludeSymbols: (state, action: PayloadAction<string>) => {
      // first check it isn't in the array already, if it is, don't add it
      if (state.excludeSymbol.indexOf(action.payload) === -1) {
        state.excludeSymbol.push(action.payload)
      }
    },
    removeFromExcludeSymbols: (state, action: PayloadAction<string>) => {
      const index = state.excludeSymbol.indexOf(action.payload)
      if (index !== -1) {
        state.excludeSymbol.splice(index, 1)
      }
    },
    addToExcludeIndustries: (state, action: PayloadAction<string>) => {
      // first check it isn't in the array already, if it is, don't add it
      if (state.excludeIndustry.indexOf(action.payload) === -1) {
        state.excludeIndustry.push(action.payload)
      }
    },
    removeFromExcludeIndustries: (state, action: PayloadAction<string>) => {
      const index = state.excludeIndustry.indexOf(action.payload)
      if (index !== -1) {
        state.excludeIndustry.splice(index, 1)
      }
    },
    addToExcludeSectors: (state, action: PayloadAction<string>) => {
      // first check it isn't in the array already, if it is, don't add it
      if (state.excludeSector.indexOf(action.payload) === -1) {
        state.excludeSector.push(action.payload)
      }
    },
    removeFromExcludeSectors: (state, action: PayloadAction<string>) => {
      const index = state.excludeSector.indexOf(action.payload)
      if (index !== -1) {
        state.excludeSector.splice(index, 1)
      }
    },
    addToExcludeExchanges: (state, action: PayloadAction<string>) => {
      // first check it isn't in the array already, if it is, don't add it
      if (state.excludeExchange.indexOf(action.payload) === -1) {
        state.excludeExchange.push(action.payload)
      }
    },
    removeFromExcludeExchanges: (state, action: PayloadAction<string>) => {
      const index = state.excludeExchange.indexOf(action.payload)
      if (index !== -1) {
        state.excludeExchange.splice(index, 1)
      }
    },
    addToExcludeCountries: (state, action: PayloadAction<string>) => {
      // first check it isn't in the array already, if it is, don't add it
      if (state.excludeCountry.indexOf(action.payload) === -1) {
        state.excludeCountry.push(action.payload)
      }
    },
    removeFromExcludeCountries: (state, action: PayloadAction<string>) => {
      const index = state.excludeCountry.indexOf(action.payload)
      if (index !== -1) {
        state.excludeCountry.splice(index, 1)
      }
    },
    updateOptions: (state, action: PayloadAction<Array<IOption>>) => {
      const newOptions = action.payload
      state.options = state.options.map((existingOption) => {
        const newMatchingOption = newOptions.find((newOption) => newOption.symbol === existingOption.symbol)
        // if we have an existing option, we need to replace all properties from the incoming option that are not undefined
        if (newMatchingOption) {
          // loop over all properties of the incoming option
          for (const [key, value] of Object.entries(newMatchingOption)) {
            if (key === 'lastPrice' || key === 'bid' || key === 'ask' || key == 'totalVolume' || key === 'volatility' || key === 'delta') {
              if (value !== undefined) {
                if (key === 'delta') {
                  // can also set probability of profit here - rounded to 2 decimal places
                  existingOption.probabilityOfProfit = Math.round((100 - value * 100) * 100) / 100
                }
                ;(existingOption as any)[key] = value
              }
            }
          }
          return existingOption
        }
        // if we don't have an existing option, we just return the incoming option
        return existingOption
      })
    },
    updateQuotes: (state, action: PayloadAction<Array<IQuote>>) => {
      const quotes = action.payload
      state.options = state.options.map((option) => {
        const matchingOption = quotes.find((quote) => quote.ticker === option.underlying)
        if (matchingOption && matchingOption.price !== undefined) {
          return {
            ...option,
            currentPrice: matchingOption.price
          }
        }
        return option
      })
    },
    setAreOptionsLoading: (state, action: PayloadAction<boolean>) => {
      state.areOptionsLoading = action.payload
    }
  }
})

export const {
  setOptions,
  updateOption,
  setMinMaxData,
  setPageIndex,
  setPageCount,
  setOrderBy,
  setOrderDirection,
  setFilteredCount,
  setFreePageCount,
  setSAndPPageCount,
  setStrategy,
  setSearchQuery,
  setDatasetInfo,
  setNumberOfFreeOptions,
  setNumberOfSAndPOptions,
  setOptionQueryParamKeyValuePair,
  upsertOptionDataFilter,
  resetFilters,
  setSymbols,
  addToSymbols,
  removeFromSymbols,
  addToIndustries,
  removeFromIndustries,
  addToSectors,
  removeFromSectors,
  addToExchanges,
  removeFromExchanges,
  addToCountries,
  removeFromCountries,
  addToExcludeSymbols,
  removeFromExcludeSymbols,
  addToExcludeIndustries,
  removeFromExcludeIndustries,
  addToExcludeSectors,
  removeFromExcludeSectors,
  addToExcludeExchanges,
  removeFromExcludeExchanges,
  addToExcludeCountries,
  removeFromExcludeCountries,
  updateOptions,
  updateQuotes,
  setAreOptionsLoading
} = optionsDataSlice.actions

export default optionsDataSlice.reducer
