import { ISector } from '../interfaces/ISector'
import { sectorsConfig } from './../config/sectorsConfig'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface SectorsState {
  sectors: Array<ISector>
}

export const sectorsInitialState: SectorsState = {
  sectors: sectorsConfig
}

export const sectorsSlice = createSlice({
  name: 'sectors',
  initialState: sectorsInitialState,
  reducers: {
    setSectors: (state, action: PayloadAction<Array<ISector>>) => {
      state.sectors = action.payload
    }
  }
})

export const { setSectors } = sectorsSlice.actions

export default sectorsSlice.reducer
