exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-account-information-tsx": () => import("./../../../src/pages/account-information.tsx" /* webpackChunkName: "component---src-pages-account-information-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-brokers-tsx": () => import("./../../../src/pages/brokers.tsx" /* webpackChunkName: "component---src-pages-brokers-tsx" */),
  "component---src-pages-calculator-tsx": () => import("./../../../src/pages/calculator.tsx" /* webpackChunkName: "component---src-pages-calculator-tsx" */),
  "component---src-pages-change-log-tsx": () => import("./../../../src/pages/change-log.tsx" /* webpackChunkName: "component---src-pages-change-log-tsx" */),
  "component---src-pages-confirmation-tsx": () => import("./../../../src/pages/confirmation.tsx" /* webpackChunkName: "component---src-pages-confirmation-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-countries-tsx": () => import("./../../../src/pages/countries.tsx" /* webpackChunkName: "component---src-pages-countries-tsx" */),
  "component---src-pages-daily-plays-newsletter-tsx": () => import("./../../../src/pages/daily-plays-newsletter.tsx" /* webpackChunkName: "component---src-pages-daily-plays-newsletter-tsx" */),
  "component---src-pages-dashboard-customize-index-tsx": () => import("./../../../src/pages/dashboard/customize/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-customize-index-tsx" */),
  "component---src-pages-dashboard-customize-metric-tsx": () => import("./../../../src/pages/dashboard/customize/metric.tsx" /* webpackChunkName: "component---src-pages-dashboard-customize-metric-tsx" */),
  "component---src-pages-dashboard-filters-tsx": () => import("./../../../src/pages/dashboard/filters.tsx" /* webpackChunkName: "component---src-pages-dashboard-filters-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-deck-of-52-tsx": () => import("./../../../src/pages/deck-of-52.tsx" /* webpackChunkName: "component---src-pages-deck-of-52-tsx" */),
  "component---src-pages-exchanges-tsx": () => import("./../../../src/pages/exchanges.tsx" /* webpackChunkName: "component---src-pages-exchanges-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-getting-started-video-tsx": () => import("./../../../src/pages/getting-started-video.tsx" /* webpackChunkName: "component---src-pages-getting-started-video-tsx" */),
  "component---src-pages-glossary-tsx": () => import("./../../../src/pages/glossary.tsx" /* webpackChunkName: "component---src-pages-glossary-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../../../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-tsx": () => import("./../../../src/pages/industries.tsx" /* webpackChunkName: "component---src-pages-industries-tsx" */),
  "component---src-pages-interactive-guide-tsx": () => import("./../../../src/pages/interactive-guide.tsx" /* webpackChunkName: "component---src-pages-interactive-guide-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-model-assumptions-and-formulae-tsx": () => import("./../../../src/pages/model-assumptions-and-formulae.tsx" /* webpackChunkName: "component---src-pages-model-assumptions-and-formulae-tsx" */),
  "component---src-pages-option-details-tsx": () => import("./../../../src/pages/option-details.tsx" /* webpackChunkName: "component---src-pages-option-details-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-risk-disclosure-tsx": () => import("./../../../src/pages/risk-disclosure.tsx" /* webpackChunkName: "component---src-pages-risk-disclosure-tsx" */),
  "component---src-pages-sectors-tsx": () => import("./../../../src/pages/sectors.tsx" /* webpackChunkName: "component---src-pages-sectors-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-stocks-tsx": () => import("./../../../src/pages/stocks.tsx" /* webpackChunkName: "component---src-pages-stocks-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-ticker-details-tsx": () => import("./../../../src/pages/ticker-details.tsx" /* webpackChunkName: "component---src-pages-ticker-details-tsx" */),
  "component---src-pages-trade-tsx": () => import("./../../../src/pages/trade.tsx" /* webpackChunkName: "component---src-pages-trade-tsx" */),
  "component---src-pages-upgrade-tsx": () => import("./../../../src/pages/upgrade.tsx" /* webpackChunkName: "component---src-pages-upgrade-tsx" */),
  "component---src-pages-wall-of-fame-tsx": () => import("./../../../src/pages/wall-of-fame.tsx" /* webpackChunkName: "component---src-pages-wall-of-fame-tsx" */),
  "component---src-pages-what-is-the-wheel-tsx": () => import("./../../../src/pages/what-is-the-wheel.tsx" /* webpackChunkName: "component---src-pages-what-is-the-wheel-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-glossary-definition-tsx": () => import("./../../../src/templates/glossary-definition.tsx" /* webpackChunkName: "component---src-templates-glossary-definition-tsx" */)
}

