import sectorsSlice, { sectorsInitialState } from './sectorsSlice'
import industriesSlice, { industriesInitialState } from './industriesSlice'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import formsSlice, { formsInitialState } from './formsSlice'
import netlifySlice, { netlifyInitialState } from './netlifySlice'
import userPreferencesSlice, { initialUsersPreferencesState } from './userPreferencesSlice'
import brokerSlice, { brokerInitialState } from './brokerSlice'
import optionsDataSlice, { optionsDataInitialState } from './optionsDataSlice'
import stocksSlice, { stocksInitialState } from './stocksSlice'
import countriesSlice, { countriesInitialState } from './countriesSlice'
import zeroDteSlice, { zeroDteInitialState } from './zeroDteSlice'
import tdameritradeSlice, { tdameritradeInitialState } from './tdameritradeSlice'
import tradierSlice, { tradierInitialState } from './tradierSlice'
import tradeStationSlice, { tradeStationInitialState } from './tradestationSlice'

export const initialState = {
  netlify: netlifyInitialState,
  forms: formsInitialState,
  userPreferences: initialUsersPreferencesState,
  broker: brokerInitialState,
  optionsData: optionsDataInitialState,
  stocks: stocksInitialState,
  industries: industriesInitialState,
  sectors: sectorsInitialState,
  countries: countriesInitialState,
  zeroDte: zeroDteInitialState,
  tdameritrade: tdameritradeInitialState,
  tradier: tradierInitialState,
  tradeStation: tradeStationInitialState
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userPreferences', 'broker', 'netlify', 'industries', 'sectors', 'countries'],
  blacklist: ['optionsData']
}

export const rootReducer = combineReducers({
  netlify: netlifySlice,
  forms: formsSlice,
  userPreferences: userPreferencesSlice,
  broker: brokerSlice,
  optionsData: optionsDataSlice,
  stocks: stocksSlice,
  industries: industriesSlice,
  sectors: sectorsSlice,
  countries: countriesSlice,
  zeroDte: zeroDteSlice,
  tdameritrade: tdameritradeSlice,
  tradier: tradierSlice,
  tradeStation: tradeStationSlice
})

export const persistedReducer = persistReducer(persistConfig, rootReducer)

// export store
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
})

// export persistor
// const persistor = persistStore(store)

// configure and export types for hooks
type ConfiguredStore = typeof store
type StoreGetState = ConfiguredStore['getState']
export type RootState = ReturnType<StoreGetState>
export type AppDispatch = ConfiguredStore['dispatch']
