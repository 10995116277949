import { Constants } from '../constants/Constants'
import { ICustomizableTableConfigItem } from './defaultTableConfig'

// the ordering of columns is implicit from the order of the array
// also acts as intitial redux state
export const zeroDteTableConfig: Array<ICustomizableTableConfigItem> = [
  // {
  //   property: 'symbol',
  //   isSortActive: false,
  //   isSortDescending: true,
  //   isSortable: true,
  //   isVisible: true,
  //   label: 'Symbol',
  //   headerCellClassName: 'text-center',
  //   cellClassName: 'text-center',
  //   customCellStyles: { color: Constants.COLORS.GREEN, fontWeight: 'bold' }
  // },
  {
    property: 'strategy',
    isSortActive: true,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'Strike',
    prefix: '$',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center',
    customCellStyles: { color: Constants.COLORS.GREEN, fontWeight: 'bold' }
  },
  {
    property: 'strikePrice',
    isSortActive: true,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'Strike',
    prefix: '$',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center',
    customCellStyles: { color: Constants.COLORS.GREEN, fontWeight: 'bold' }
  },
  {
    property: 'mark',
    isSortActive: false,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'Price',
    prefix: '$',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center',
    customCellStyles: { color: Constants.COLORS.GREEN, fontWeight: 'bold' }
  },
  {
    property: 'bid',
    isSortActive: false,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'Bid',
    prefix: '$',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center',
    customCellStyles: { color: Constants.COLORS.GREEN, fontWeight: 'bold' }
  },
  {
    property: 'ask',
    isSortActive: false,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'Ask',
    prefix: '$',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center',
    customCellStyles: { color: Constants.COLORS.RED, fontWeight: 'bold' }
  },
  {
    property: 'bidSize',
    isSortActive: false,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'Bid Size',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center',
    customCellStyles: { color: Constants.COLORS.GREEN, fontWeight: 'bold' }
  },
  {
    property: 'askSize',
    isSortActive: false,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'Ask Size',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center',
    customCellStyles: { color: Constants.COLORS.RED, fontWeight: 'bold' }
  },
  {
    property: 'openInterest',
    isSortActive: false,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'Open Interest',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center'
  },
  {
    property: 'totalVolume',
    isSortActive: false,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'Volume',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center'
  },
  {
    property: 'maxProfit',
    isSortActive: false,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'Max Profit',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center',
    prefix: '$',
    customCellStyles: { color: Constants.COLORS.GREEN, fontWeight: 'bold' }
  },
  {
    property: 'maxLoss',
    isSortActive: false,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'Max Loss',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center',
    prefix: '$',
    customCellStyles: { color: Constants.COLORS.RED, fontWeight: 'bold' }
  },
  {
    property: 'breakEvenPrice',
    isSortActive: false,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'Breakeven',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center',
    prefix: '$',
    customCellStyles: { color: Constants.COLORS.GOLD, fontWeight: 'bold' }
  },
  {
    property: 'breakEvenPercent',
    isSortActive: false,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'To Breakeven',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center',
    suffix: '%',
    customCellStyles: { color: Constants.COLORS.GOLD, fontWeight: 'bold' }
  },
  {
    property: 'rewardToRiskRatio',
    isSortActive: false,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'P/L',
    suffix: '%',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center'
  },
  {
    property: 'probabilityOfProfit',
    isSortActive: false,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'POP',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center'
  },
  {
    property: 'delta',
    isSortActive: false,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'Delta',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center'
  },
  {
    property: 'gamma',
    isSortActive: false,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'Gamma',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center'
  },
  {
    property: 'theta',
    isSortActive: false,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'Theta',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center'
  },
  {
    property: 'vega',
    isSortActive: false,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'Vega',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center'
  },
  {
    property: 'rho',
    isSortActive: false,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'Rho',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center'
  },
  {
    property: 'volatility',
    isSortActive: false,
    isSortDescending: true,
    isSortable: true,
    isVisible: true,
    label: 'IV',
    suffix: '%',
    headerCellClassName: 'text-center',
    cellClassName: 'text-center'
  }
]
