import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import Broker from '../enums/Broker'
import UserRole from '../enums/UserState'
import INetlifyUser from '../interfaces/INetlifyUser'

export interface NetlifyState {
  user: INetlifyUser | null
  userState: UserRole
  userBroker: Broker
  deletionEmail: string
}

export const netlifyInitialState: NetlifyState = {
  user: null,
  userState: UserRole.FREE,
  userBroker: Broker.NONE,
  deletionEmail: ''
}

export const netlifySlice = createSlice({
  name: 'netlify',
  initialState: netlifyInitialState,
  reducers: {
    setUser: (state, action: PayloadAction<INetlifyUser | null>) => {
      state.user = action.payload
    },
    setUserState: (state, action: PayloadAction<UserRole>) => {
      state.userState = action.payload
    },
    setUserBroker: (state, action: PayloadAction<Broker>) => {
      state.userBroker = action.payload
    },
    setDeletionEmail: (state, action: PayloadAction<string>) => {
      state.deletionEmail = action.payload
    },
    resetDeletionEmail: (state) => {
      state.deletionEmail = ''
    }
  }
})

export const { setUser, setUserState, setUserBroker, setDeletionEmail, resetDeletionEmail } = netlifySlice.actions

export default netlifySlice.reducer
