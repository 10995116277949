import { IIndustry } from '../interfaces/IIndustry'

export const industriesConfig: Array<IIndustry> = [
  { name: 'Advertising Agencies' },
  { name: 'Aerospace Defense' },
  { name: 'Agricultural Inputs' },
  { name: 'Airlines' },
  { name: 'Airports Air Services' },
  { name: 'Apparel Manufacturing' },
  { name: 'Apparel Retail' },
  { name: 'Asset Management' },
  { name: 'Auto Manufacturers' },
  { name: 'Auto Parts' },
  { name: 'Auto Truck Dealerships' },
  { name: 'Banks - Diversified' },
  { name: 'Banks - Regional' },
  { name: 'Beverages - Wineries Distilleries' },
  { name: 'Biotechnology' },
  { name: 'Broadcasting' },
  { name: 'Building Materials' },
  { name: 'Building Products Equipment' },
  { name: 'Capital Markets' },
  { name: 'Chemicals' },
  { name: 'Closed-End Fund - Debt' },
  { name: 'Communication Equipment' },
  { name: 'Computer Hardware' },
  { name: 'Confectioners' },
  { name: 'Conglomerates' },
  { name: 'Consumer Electronics' },
  { name: 'Copper' },
  { name: 'Credit Services' },
  { name: 'Department Stores' },
  { name: 'Diagnostics Research' },
  { name: 'Discount Stores' },
  { name: 'Drug Manufacturers - General' },
  { name: 'Education Training Services' },
  { name: 'Electrical Equipment Parts' },
  { name: 'Electronic Components' },
  { name: 'Electronic Gaming Multimedia' },
  { name: 'Electronics Computer Distribution' },
  { name: 'Engineering Construction' },
  { name: 'Entertainment' },
  { name: 'Exchange Traded Fund' },
  { name: 'Farm Heavy Construction Machinery' },
  { name: 'Farm Products' },
  { name: 'Financial Conglomerates' },
  { name: 'Financial Data Stock Exchanges' },
  { name: 'Food Distribution' },
  { name: 'Footwear Accessories' },
  { name: 'Furnishings, Fixtures Appliances' },
  { name: 'Gambling' },
  { name: 'Gold' },
  { name: 'Grocery Stores' },
  { name: 'Health Information Services' },
  { name: 'Healthcare Plans' },
  { name: 'Home Improvement Retail' },
  { name: 'Household Personal Products' },
  { name: 'Insurance - Diversified' },
  { name: 'Insurance - Life' },
  { name: 'Insurance - Property Casualty' },
  { name: 'Insurance - Reinsurance' },
  { name: 'Insurance Brokers' },
  { name: 'Internet Content Information' },
  { name: 'Internet Retail' },
  { name: 'Leisure' },
  { name: 'Lodging' },
  { name: 'Lumber Wood Production' },
  { name: 'Luxury Goods' },
  { name: 'Medical Care Facilities' },
  { name: 'Medical Devices' },
  { name: 'Medical Distribution' },
  { name: 'Medical Instruments Supplies' },
  { name: 'Metal Fabrication' },
  { name: 'Mortgage Finance' },
  { name: 'Oil Gas Drilling' },
  { name: 'Oil Gas EP' },
  { name: 'Oil Gas Equipment Services' },
  { name: 'Oil Gas Integrated' },
  { name: 'Oil Gas Midstream' },
  { name: 'Oil Gas Refining Marketing' },
  { name: 'Other Industrial Metals Mining' },
  { name: 'Other Precious Metals Mining' },
  { name: 'Packaged Foods' },
  { name: 'Packaging Containers' },
  { name: 'Paper Paper Products' },
  { name: 'Personal Services' },
  { name: 'Pharmaceutical Retailers' },
  { name: 'Pollution Treatment Controls' },
  { name: 'Publishing' },
  { name: 'Railroads' },
  { name: 'Real Estate - Diversified' },
  { name: 'Real Estate Services' },
  { name: 'REIT - Diversified' },
  { name: 'REIT - Healthcare Facilities' },
  { name: 'REIT - Hotel Motel' },
  { name: 'REIT - Industrial' },
  { name: 'REIT - Mortgage' },
  { name: 'REIT - Office' },
  { name: 'REIT - Residential' },
  { name: 'REIT - Retail' },
  { name: 'REIT - Specialty' },
  { name: 'Residential Construction' },
  { name: 'Resorts Casinos' },
  { name: 'Restaurants' },
  { name: 'Scientific Technical Instruments' },
  { name: 'Security Protection Services' },
  { name: 'Silver' },
  { name: 'Software - Application' },
  { name: 'Software - Infrastructure' },
  { name: 'Solar' },
  { name: 'Specialty Business Services' },
  { name: 'Specialty Chemicals' },
  { name: 'Specialty Industrial Machinery' },
  { name: 'Specialty Retail' },
  { name: 'Staffing Employment Services' },
  { name: 'Steel' },
  { name: 'Telecom Services' },
  { name: 'Thermal Coal' },
  { name: 'Tobacco' },
  { name: 'Tools Accessories' },
  { name: 'Travel Services' },
  { name: 'Trucking' },
  { name: 'Uranium' },
  { name: 'Utilities - Diversified' },
  { name: 'Utilities - Independent Power Producers' },
  { name: 'Utilities - Regulated Electric' },
  { name: 'Utilities - Regulated Gas' },
  { name: 'Utilities - Regulated Water' },
  { name: 'Utilities - Renewable' },
  { name: 'Waste Management' }
]
