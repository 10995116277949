import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IIndustry } from '../interfaces/IIndustry'
import { industriesConfig } from '../config/industriesConfig'

export interface IndustriesState {
  industries: Array<IIndustry>
}

export const industriesInitialState: IndustriesState = {
  industries: industriesConfig
}

export const industriesSlice = createSlice({
  name: 'industries',
  initialState: industriesInitialState,
  reducers: {
    setIndustries: (state, action: PayloadAction<Array<IIndustry>>) => {
      state.industries = action.payload
    }
  }
})

export const { setIndustries } = industriesSlice.actions

export default industriesSlice.reducer
