import { ISector } from '../interfaces/ISector'

export const sectorsConfig: Array<ISector> = [
  { name: 'Basic Materials' },
  { name: 'Communication Services' },
  { name: 'Consumer Cyclical' },
  { name: 'Consumer Defensive' },
  { name: 'Energy' },
  { name: 'Financial' },
  { name: 'Healthcare' },
  { name: 'Industrials' },
  { name: 'Real Estate' },
  { name: 'Technology' },
  { name: 'Utilities' }
]
