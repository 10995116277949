import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import MarketBellSoundPreference from '../enums/MarketBellSoundPreference'

export interface UserPreferencesState {
  prefersLightMode: boolean
  prefersMarketBellSounds: MarketBellSoundPreference
}

export const initialUsersPreferencesState: UserPreferencesState = {
  prefersLightMode: true,
  prefersMarketBellSounds: MarketBellSoundPreference.NEVER
}

export const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState: initialUsersPreferencesState,
  reducers: {
    setPrefersLightMode: (state, action: PayloadAction<boolean>) => {
      state.prefersLightMode = action.payload
    },
    setPrefersMarketBellSounds: (state, action: PayloadAction<MarketBellSoundPreference>) => {
      state.prefersMarketBellSounds = action.payload
    }
  }
})

export const { setPrefersLightMode, setPrefersMarketBellSounds } = userPreferencesSlice.actions

export default userPreferencesSlice.reducer
